

/* Timepicker */
.bootstrap-timepicker-widget {
  table {
    td {
      a {
        color: $input-color;
        &:hover {
          background-color: transparent;
          border-color: transparent;
          border-radius: 4px;
          color: $primary;
          text-decoration: none;
        }
      }
      input {
        width: 32px;
        height: 32px;
        border: 0;
        color: $body-color;
        border: 1px solid $border-color;;
        background-color: $input-bg;

      }
    }
  }

  &.dropdown-menu:after{
    border-bottom-color: $gray-200;
  }
  &.timepicker-orient-bottom:after{
    border-top-color: $gray-200;
  }
}